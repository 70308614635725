.cx_container-wrapper {
    overflow: hidden;
}

.cx_ls-main-header {
    .cx_humberburg-button {
        opacity: 1;
    }
    
    .cx_navbar {
        padding: 24px 40px;

        @media (max-width: 1024px) {
            padding: 24px 30px;
        }
    }
}

.cx_main-header.cx_ls-main-header.cx_main-header--scrolled {
    .cx_navbar {
        padding: 24px 40px;
        
        @media (max-width: 1024px) {
            padding: 24px 30px;
        }
    }
    .cx_nav-logo {
        a {
            display: none;
        }
    }
}

.cx_ls-main-header.cx_main-header {

    .menu-open {
        .cx_navbar-menu {
            li {
                a {
                    font-size: 22px;
                    padding: 3px 
                }
            }
        }

        ~ .cx_mobile-overlay {
            bottom: 0;
            visibility: visible;
        }
    }

}
.cx_ls-hero-section {
    width: 100%;
    z-index: 0;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 50% 95%, 50% 100%, 0 100%);
    background: rgb(233, 0, 205);
    background: linear-gradient(180deg, rgb(233, 0, 205) 0%, rgb(255, 63, 11) 100%);
    align-items: flex-end;
    display: flex;
    min-height: 100vh;
    overflow: hidden;
    position: relative;

    .cx_ls-container {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.cx_ls-container {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 750px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.cx_hero-title {
    font-size: 70px;
    color: #ffffff;
    font-weight: 600;
}

.cx_ls-belive-section {
    padding-top: 130px;
    padding-bottom: 160px;
    position: relative;


    .cx_text {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 110px;
        max-width: 510px;
    }

    .cx_ls-container {
        position: relative;
        z-index: 2;
    }
}

.cx_ls-connecting-banner--left {
    position: absolute;
    left: -20px;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='272.73' height='227.937' viewBox='0 0 272.73 227.937'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_113' data-name='Group 113' transform='translate(44.422 -1650.86)'%3E%3Crect id='Rectangle_85' data-name='Rectangle 85' width='14.696' height='138.452' transform='translate(228.308 1871.228) rotate(149)' fill='url(%23linear-gradient)'/%3E%3Crect id='Rectangle_87' data-name='Rectangle 87' width='13.977' height='107.107' transform='matrix(0.326, 0.946, -0.946, 0.326, 162.057, 1650.86)' fill='url(%23linear-gradient)'/%3E%3Crect id='Rectangle_88' data-name='Rectangle 88' width='26.894' height='137.398' transform='matrix(-0.559, -0.829, 0.829, -0.559, 41.429, 1810.489)' fill='url(%23linear-gradient)'/%3E%3Crect id='Rectangle_89' data-name='Rectangle 89' width='6.26' height='138.452' transform='translate(84.607 1793.456) rotate(114)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
    width: 270px;
    height: 230px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.cx_ls-connecting-banner--right {
    position: absolute;
    right: -100px;
    top: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='229.718' height='246.615' viewBox='0 0 229.718 246.615'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_112' data-name='Group 112' transform='translate(-530.944 -698.117)'%3E%3Crect id='Rectangle_125' data-name='Rectangle 125' width='36.794' height='210.944' transform='matrix(-0.559, -0.829, 0.829, -0.559, 585.782, 944.732)' fill='url(%23linear-gradient)'/%3E%3Crect id='Rectangle_126' data-name='Rectangle 126' width='19.066' height='146.097' transform='translate(618.51 831.583) rotate(156)' fill='url(%23linear-gradient)'/%3E%3Crect id='Rectangle_127' data-name='Rectangle 127' width='8.899' height='76.578' transform='matrix(-0.788, -0.616, 0.616, -0.788, 537.956, 796.27)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
    width: 230px;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.cx_connnecting-heading {
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    font-weight: 600;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
}

.cx_ls-connecting-banner {
    position: absolute;
    left: -160px;
    top: 0px;
    z-index: 0;
    max-width: 1030px;
}

.cx_ls-innovation-section {
    position: relative;
}

.cx_content-card {
    max-width: 550px;
    .cx_ls-title {
        font-size: 48px;
        line-height: 48px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 400;
    }
}

.cx_ls-connecting-banner--two-right {
    position: absolute;
    right: 0px;
    top: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='253.707' height='148.927' viewBox='0 0 253.707 148.927'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_114' data-name='Group 114' transform='translate(-1027.067 -2129.708)'%3E%3Crect id='Rectangle_128' data-name='Rectangle 128' width='19.373' height='148.455' transform='matrix(-0.208, 0.978, -0.978, -0.208, 1176.306, 2160.574)' fill='url(%23linear-gradient)'/%3E%3Crect id='Rectangle_129' data-name='Rectangle 129' width='14.17' height='77.244' transform='matrix(-0.883, -0.469, 0.469, -0.883, 1244.51, 2278.636)' fill='url(%23linear-gradient)'/%3E%3Crect id='Rectangle_130' data-name='Rectangle 130' width='6.764' height='63.572' transform='translate(1166.5 2270.305) rotate(-111)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
    width: 260px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.cx_ls-brand-section {
    position: relative;
    padding-top: 100px;

    .cx_ls-container {
        position: relative;
        z-index: 1;
    }
}

.cx_ls-brand-banner {
    position: absolute;
    right: -20px;
    top: -90px;
    z-index: 0;
}


.cx_ls-weare-content {
    padding-top: 90px;
    h2 {
        font-size: 48px;
        text-align: center;
        line-height: 58px;
        font-weight: 600;
        margin-bottom: 90px;
        letter-spacing: -2px;
    }
}

.cx_ls-make-section {
    padding-top: 70px;
    padding-bottom: 50px;
    position: relative;
}

.cx_ls-what-newchapter-section {
    padding-bottom: 120px;
    .cx_logo {
        max-width: 100%;
        margin-bottom: 80px;
        svg {
            max-width: 100%;
        }

    }
}

.cx_ls-brand-box__inner {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
}

.cx_ls-brand-box__item {
    max-width: 33.33%;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;

    img {
        max-width: 100%;
        height: 100px;
        object-fit: contain;
        opacity: 0.15;
    }
}

.cx_ls-wemake-wrapper {
    margin-top: 40px;
}

.cx_ls-wemake-item {
    margin-bottom: 25px;
    transition: height ease-in-out 0.3s;
    &.open {
        .cx_ls-wemake-card_body-inner {
            height: auto;
            transition: height ease-in-out 0.3s;
            opacity: 1;
            visibility: visible;
        }
    }
}

.cx_ls-wemake-card {
    position: relative;

    &::after {
        content: "";
        width: 100%;
        height: 2px;
        background-image: linear-gradient(90deg, rgb(233, 0, 205) 0%, rgb(255, 63, 11) 100%);
        background-color: rgb(233, 0, 205);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transition: width 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    }
}

.cx_ls-wemake-card_header {
    position: relative;

    .cx_ls-title {
        font-size: 24px;
        line-height: 28px;
        color: #292929;
        margin-bottom: 12px;
        font-weight: 500;
    }

    .cx_ls-subtitle {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }
}

.cx_ls-wemake-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-appearance: button;
    transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    font-size: 16px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    font-family: "Sora", sans-serif;
    color: #FF0089;
    margin-bottom: 13px;

    svg {
        transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
        width: 16px;
        height: 16px;
        margin-left: 10px;
    }

    &.active {
        svg {
            transform: rotate(90deg);
        }

        .line-horizontal {
            transform: scale(0);
        }
    }
}

.cx_ls-wemake-card_body {
    padding-top: 16px;
    .cx_ls-wemake-card_body-inner {
        opacity: 0;
        visibility: hidden;
        transition: height ease-in-out 0.3s;
        overflow: hidden;
        height: 0;
    }
}

.cx_ls-wemake-list {
    list-style: none;
    padding-bottom: 16px;

    li {
        font-size: 16px;
        font-weight: 400;
    }
}

.cx_ls-insight-section {
    padding-bottom: 70px;

    .cx_section-title {
        margin-bottom: 36px;

        .cx_title {
            font-size: 24px;
            line-height: 28px;
        }
    }
    
    .cx_insight-card {
        margin-bottom: 50px;
    }

    .cx_insight-card__content {
        padding-top: 30px;
    }

    .cx_insight-card__title {
        font-size: 20px;
        line-height: 28px;
    }
}

.cx_ls-nextchapter {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;

    .cx_flashing-cursor {
        height: 70px;
    }
}

.cx_ls-nextchapter-title {
    font-size: 75px;
    line-height: 75px;
    color: #292929;
    font-weight: 600;
    margin-bottom: 40px;
    background: -webkit-linear-gradient(0deg, #e900ce 0%, #ff7d00 90%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cx_ls-main-footer {
    padding-left: 10px;
    padding-right: 10px;
    .cx_container {
        max-width: 820px;
    }
}
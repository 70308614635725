/** @format */
html {
    scroll-behavior: none !important;
    padding: env(safe-area-inset);
}

body {
    scroll-behavior: none !important;
    margin: 0;
    padding: 0;
}

#smooth-content {
    width: 100%;
    will-change: transform;
    height: auto;
    /* set a height because the contents are position: absolute, thus natively there's no height */
}

.cx_container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1285px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media (max-width: 1199.98px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 991.98px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 767.98px) {
        padding-left: 14px;
        padding-right: 14px;
    }
}

main {
    overflow: hidden;
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem;
}

.cx_pb-0 {
    padding-bottom: 0 !important;
}

.cx_pt-40 {
    padding-top: 40px !important;

    @media (max-width: 1199.98px) {
        padding-top: 30px !important;
    }
    
    @media (max-width: 998.98px) {
        padding-top: 25px !important;        
    }

    @media (max-width: 767.98px) {
        padding-top: 14px !important;
    }
}

.cx_img-fluid {
    max-width: 100%;
}

.cx_bg-black {
    background-color: #000000;
}

.cx_link {
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
}

.cx_link-primary {
    color: #FF0089;
}

.cx_link-primary:hover {
    color: #FF0089;
    text-decoration: underline;
}

.cx_row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.cx_row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

.cx_col {
    flex: 1 0 0%;
}

.cx_col-12 {
    flex: 0 0 auto;
    width: 100%;
}

@media (min-width: 768px) {
    .cx_col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .cx_col-md-10 {
        flex: 0 0 auto;
        width: 75%;
    }
}

@media (min-width: 992px) {
    .cx_col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .cx_col-lg-5 {
        flex: 0 0 auto;
        width: 42%;
    }

    .cx_col-lg-7 {
        flex: 0 0 auto;
        width: 58%;
    }
}

.cx_overlay {
    position: absolute;
    width: 100%;
    height: 30px;
    z-index: 0;
    right: -200vw;
    clip-path: polygon(0 0, 100% 0, 100% 97%, 50% 97%, 50% 100%, 0 100%);

    &.first {
        background: rgb(233, 0, 205);
        background: linear-gradient(180deg,
                rgba(233, 0, 205, 1) 0%,
                rgba(255, 63, 11, 1) 100%);
    }
}

.cx_split {
    overflow: hidden;

    span {
        overflow: hidden;
    }
}

.cx_parallax-bg {
    position: relative;
    transition: all ease-in-out 0.3s;

    svg {
        max-width: 100%;
        will-change: transform;

        * {
            will-change: transform;
        }
    }
}

.cx_section-title {
    margin-bottom: 60px;

    .cx_title {
        color: #292929;
        font-size: 74px;
        line-height: 78px;
        font-weight: 600;

        &.cx_title--sm {
            font-size: 32px;
            line-height: normal;
            font-weight: 500;
        }
    }

    @media (max-width: 991.98px) {
        margin-bottom: 40px;

        .cx_title {
            font-size: 72px;
            line-height: 72px;
        }
    }

    @media (max-width: 767.98px) {
        margin-bottom: 40px;

        .cx_title {
            font-size: 48px;
            line-height: 58px;
        }
    }
}

/*Header CSS Start*/
.cx_main-header {
    position: fixed;
    z-index: 5;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;

    &.cx_main-header--menuOpen {
        .cx_mobile-overlay {
            display: block;
            visibility: visible;
            height: auto;
        }
    }

    &.cx_main-header--scrolled {
        position: fixed;



        .cx_navbar-menu-desk {
            display: none;
        }

        .cx_navbar-menu-mobile {
            display: flex;
        }

        .cx_navbar-menu {
            display: none;
        }

        .cx_navbar {
            transition: padding ease-in-out 0.3s;

            @media (max-width: 1199.98px) {
                padding: 30px 40px;
            }

            @media (max-width: 991.98px) {
                padding: 24px 30px;
            }
        }
    }

    .cx_navbar-menu-mobile {
        display: none;
    }

    .cx_mobile-overlay {
        display: block;
        background-color: #292929;
        position: absolute;
        height: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
        visibility: hidden;
        transition: height, visibility cubic-bezier(0.2, 0.01, 0.8, 0.99) 1s;
    }

    .cx_nav-logo_mobile {
        display: none;

        a {
            display: block;
        }
    }

    .menu-open {
        height: 100vh;

        @media (max-width: 991.98px) {
            ~.cx_mobile-overlay {
                display: block;
                background-color: #292929;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                z-index: 1;
            }
        }

        .cx_nav-logo_mobile {
            display: flex;
            align-self: flex-start;
        }

        .cx_nav-logo {
            display: none;
        }

        .cx_navbar-menu {
            display: block;
            order: 1;
            position: absolute;
            bottom: 120px;
            left: 100px;

            li {
                a {
                    padding: 5px 0;
                    font-size: 34px;
                    font-weight: 400;
                    color: #ff0089;

                    &::after {
                        background-color: #ff0089;
                    }

                    @media (max-width: 991.98px) {
                        font-size: 22px;
                        padding: 3px;
                    }
                }
            }

            @media (max-height: 500px) {
                bottom: 90px;
            }

            @media (max-width: 1199.98px) {
                left: 60px;
            }

            @media (max-width: 991.98px) {
                left: 30px;
            }

            @media (max-width: 767.98px) {
                left: 0;
            }
        }
    }
}

.cx_navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 40px 50px;
    z-index: 2;
    position: relative;
    transition: padding ease-in-out 0.3s;

    @media (max-width: 1199.98px) {
        padding: 30px 40px;
    }

    @media (max-width: 991.98px) {
        padding: 24px 30px;
        position: relative;
        height: 100%;
        align-items: flex-start;
    }

    @media (max-width: 767.98px) {
        padding: 24px 14px;
    }

    @media (max-height: 500px) {
        padding: 20px 40px;
    }
}

.cx_nav-logo {

    a {
        display: block;
        position: relative;
    }

    img {
        position: relative;
        z-index: 1;

        &:nth-child(2) {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: 0;

            @media (max-width: 767.98px) {
                display: none;
            }
        }
    }
}



.cx_navbar-menu {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;

    li {
        padding: 0 25px;

        a {
            padding: 10px 0;
            display: inline-block;
            text-decoration: none;
            color: #ffffff;
            font-size: 20px;
            font-weight: 400;
            position: relative;

            &::after {
                content: "";
                display: block;
                pointer-events: none;
                width: 100%;
                height: 1px;
                background-color: #ffffff;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: translateY(20px) rotate(10deg);
                opacity: 0;
                transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1),
                    opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
            }

            &:hover {
                &::after {
                    transform: translateY(0) rotate(0);
                    opacity: 1;
                }
            }
        }
    }

    @media (max-width: 991.98px) {
        display: none;
    }
}

.cx_main-header--dark {
    .cx_navbar-menu {
        li {
            a {
                color: #292929;

                &::after {
                    background-color: #292929;
                }
            }
        }
    }

    .cx_humberburg-button {
        opacity: 1;
    }

    .cx_nav-logo {
        img {
            opacity: 1;
        }
    }
}

.cx_main-header--black {

    .cx_navbar-menu {
        li {
            a {
                color: #292929;

                &::after {
                    background-color: #292929;
                }
            }
        }
    }

    .cx_humberburg-button {
        opacity: 1;
        background-color: #ffffff;
        border-radius: 100%;
        position: relative;
        width: 54px;
        height: 54px;
        top: 0;
        right: 0;

        @media (max-width: 991.98px) {
            width: 44px;
            height: 44px;
        }
    }

    .cx_nav-logo {
        img {
            opacity: 1;
        }
    }

    .cx_humberburg__line {
        background-color: #000000;
        width: 26px;
        margin: auto;

        &.cx_humberburg__line--top {
            top: 40%;
        }
        
        &.cx_humberburg__line--bottom {
            top: 60%;
        }
    }

    &.cx_header-show {
        visibility: visible;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        -webkit-transition: -webkit-transform .3s ease;
        -o-transition: -o-transform .3s ease;
        transition: transform .3s ease;
    }
    
    &.cx_header-hide {
        -webkit-transform: translate3d(0,-100%,0) !important;
        transform: translate3d(0,-100%,0) !important;
        visibility: hidden;
        -webkit-transition: -webkit-transform .3s ease,visibility 0s .3s linear;
        -o-transition: -o-transform .3s ease,visibility 0s .3s linear;
        transition: transform .3s ease,visibility 0s .3s linear;
        
    }


    .cx_humberburg-button {
        &.active {
            .cx_humberburg__line {
                background-color: #000000;
            }

        }

    }
}

.cx_humberburg-button {
    height: 30px;
    width: 34px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-appearance: button;
    position: relative;
    top: 11px;
    margin-left: auto;

    @media (max-width: 991.98px) {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        position: absolute;
        margin-top: 0;
        top: 36px;
        right: 30px;
    }

    &.active {
        .cx_humberburg__line {
            background-color: #ffffff;
        }

        .cx_humberburg__line--top {
            transform: translateY(-50%) rotate(-45deg);
            top: 50%;
        }

        .cx_humberburg__line--bottom {
            transform: translateY(-50%) rotate(45deg);
            top: 50%;
        }
    }
}

.cx_humberburg__line {
    height: 2px;
    width: 100%;
    background-color: #292929;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: absolute;
    left: 0;
    right: 0;
}

.cx_humberburg__line--top {
    transform-origin: top;
    top: 30%;
}

.cx_humberburg__line--bottom {
    transform-origin: bottom;
    top: 70%;
}

.scroll-to-top {
    position: fixed;
    bottom: 70px;
    right: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    -webkit-appearance: button;
    display: none;

    @media(max-width: 767.98px) {
        display: block;
    }
}

/*Hero Section Start CSS*/
.cx_hero-section {
    position: relative;
    min-height: 100svh;
    display: flex;
    align-items: center;

    .cx_container {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    &::after {
        content: "";
        position: absolute;
        width: 50%;
        bottom: -5px;
        right: 0;
        height: 30px;
        background-color: #ffffff;
    }

    h1 {
        font-size: 122px;
        letter-spacing: -2px;
        font-weight: 600;
        color: #ffffff;
        overflow: hidden;
        max-width: 1050px;
        display: none;

        @media (max-width: 1299.98px) {
            font-size: 92px;
            max-width: 800px;
        }

        @media (max-width: 1199.98px) {
            font-size: 82px;
            max-width: 700px;
        }

        @media (max-width: 991.98px) {
            font-size: 64px;
            max-width: 510px;
            line-height: normal;
        }

        @media (max-width: 767.98px) {
            font-size: 54px;
            line-height: normal;
            max-width: 340px;
        }

        @media (max-width: 400.98px) {
            max-width: 260px;
        }
    }
}

.cx_hero-content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 60px;


    &.onScrolledTitle {
        h1 {
            display: block;
        }

        .cx_hero-icon-desktop,
        .cx_hero-icon-mobile {
            display: none;
        }
    }

    @media (max-width: 1199.98px) {
        padding: 30px 40px;
    }

    @media (max-width: 991.98px) {
        padding: 24px 30px;
    }

    @media (max-width: 512.98px) {
        justify-content: flex-start;
    }
}

.cx_hero-icon-desktop {
    width: 100%;
    height: 100%;

    @media (max-width: 767.98px) {
        display: none;
    }
}

.cx_hero-icon-mobile {
    display: block;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
        display: none;
    }
}

.cx_hero-lottie-first,
.cx_hero-lottie-two {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 30px);
    text-align: center;

    svg {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 767.98px) {
            max-width: 100%;
        }
    }

    @media (max-width: 767.98px) {
        height: calc(100% - 50px);
        padding-left: 10px;
        padding-right: 10px;
    }

    @media (max-height: 700px) {
        bottom: 50px;
        height: calc(100% - 70px);
    }

    @media (max-height: 300px) {
        bottom: 20px;
        height: calc(100% - 50px);
    }
}

.cx_hero-down-arrow {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    max-width: 80px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}

.cx_belive-section {
    padding-top: 100px;
    position: relative;
    z-index: 2;
}

.cx_connecting-leftContent {
    max-width: 50%;
    margin-left: auto;
    position: relative;
    z-index: 2;

    @media (max-width: 991.98px) {
        max-width: 100%;
    }

    .cx_text {
        font-size: 32px;
        will-change: transform;

        @media (max-width: 767.98px) {
            font-size: 23px;
            max-width: 310px;
        }
    }
}

.cx_connecting-section {
    position: relative;
    z-index: 1;
    padding-top: 100px;

    .cx_connecting-wrapper {
        height: 500px;
        width: 100%;
        display: flex;
        position: relative;
        z-index: 1;

        @media (max-width: 991.98px) {
            height: 400px;
        }
    }

    .cx_container {
        position: relative;
        z-index: 1;
    }

    .cx_parallax-bg {
        position: absolute;
        top: -200px;
        left: -50px;
        z-index: 0;

        @media (max-width: 991.98px) {
            top: -60%;
        }

        @media (max-width: 767.98px) {
            top: -40px;
            left: 0;
            width: 100%;

            svg {
                max-width: 100%;
                width: 100%;
                height: 100vh;
            }
        }
    }

    @media (max-width: 767.98px) {
        padding-top: 0px;
    }
}

.cx_connecting-heading {
    margin-bottom: 0px;
    position: relative;
    max-width: 1050px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    h2 {
        font-size: 95px;
        color: #292929;
        font-weight: 600;
        margin-right: 20px;

        @media (max-width: 991.98px) {
            font-size: 68px;
        }

        @media (max-width: 767.98px) {
            font-size: 38px;
            line-height: 48px;
            display: none;
        }
    }

    @media (max-width: 1199.98px) {
        max-width: 700px;
    }

    @media (max-width: 991.98px) {
        max-width: 500px;
    }
}

.cx_connecting-heading__slider {
    p {
        font-size: 48px;
        line-height: 1;
        font-weight: 600;
        color: #292929;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 50px;
        text-align: center;
        will-change: transform;

        @media (max-width: 1199.98px) {
            font-size: 32px;
        }

        @media (max-width: 991.98px) {
            text-align: center;
            height: 150px;
            white-space: unset;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -1px;
            font-weight: 500;
        }

        @media (max-width: 767.98px) {
            text-align: center;
            justify-content: center;

            span {
                align-items: center;
                justify-content: center;
                height: 0;
                opacity: 0;
                visibility: hidden;
            }

            &.active {
                span {
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .empty {
        height: 160px;
    }

    .spacer {
        height: 160px;
        /* adjust this value to match the height of your h2 element */
    }
}

.cx_connecting-bg {
    height: 100vh;
    position: relative;
    z-index: 0;
}

.cx_weare-section {
    position: relative;

    .cx_weare-section_wrapper {
        height: 600px;
        width: 100%;
        // display: flex;
        position: relative;

        @media (max-width: 991.98px) {
            height: 600px;
        }

        @media (max-width: 767.98px) {
            height: 500px;
        }
    }

    .cx_container {
        position: relative;
        z-index: 1;
    }

    .cx_parallax-bg {
        position: absolute;
        top: -100px;
        left: 0;
        right: 0;
        z-index: 0;
        align-items: center;
        justify-content: center;
        z-index: -1;

        svg {
            @media (max-width: 1199.98px) {
                height: 150vh;
            }

            @media (max-width: 991.98px) {
                height: 1450px;
            }

            @media (max-width: 767.98px) {
                max-width: 860px;
                width: 100%;
                height: 1000px;
            }

            @media (max-width: 512.98px) {
                height: auto;
            }
        }

        @media (max-width: 1199.98px) {
            top: -65%;
        }

        @media (max-width: 991.98px) {
            top: -0%;
        }

        @media (max-width: 767.98px) {
            left: -0;
            top: -0%;
        }

        @media (max-width: 512.98px) {
            top: -240px;
        }
    }

    @media (max-width: 767.98px) {
        margin-bottom: -40px;
    }
}

.cx_innovation-section {
    position: relative;
    z-index: 2;

    @media (max-width: 767.98px) {
        padding-bottom: 0px;
        padding-top: 0px;
    }
}

.cx_generation-box {
    will-change: transform;

    .cx_title {
        font-size: 74px;
        color: #292929;
        margin-bottom: 30px;
        line-height: 78px;
        font-weight: 600;
        letter-spacing: -0.023em;

        @media (max-width: 991.98px) {
            font-size: 48px;
            line-height: normal;
        }

        @media (max-width: 767.98px) {
            font-size: 48px;
            max-width: 200px;
            margin-bottom: 24px;
        }
    }

    .cx_text {
        max-width: 610px;

        p {
            font-size: 20px;
            color: #292929;
            margin-bottom: 20px;

            @media (max-width: 991.98px) {
                font-size: 16px;
            }

            @media (max-width: 767.98px) {
                font-size: 16px;
                margin-bottom: 16px;
            }
        }
    }
}

.cx_brand-section {
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 200px;
    margin-top: -100px;

    @media (max-width: 767.98px) {
        padding-top: 40px;
    }

    .cx_row {
        align-items: flex-end;
    }
}

.cx_brand-box {
    padding-bottom: 50px;
}

.cx_brandtext-box {
    .cx_title {
        font-size: 74px;
        color: #292929;
        margin-bottom: 30px;
        line-height: 78px;
        font-weight: 600;
        letter-spacing: -0.023em;

        @media (max-width: 991.98px) {
            font-size: 58px;
            line-height: normal;
        }

        @media (max-width: 767.98px) {
            font-size: 48px;
            margin-bottom: 24px;
            max-width: 220px;
        }
    }

    .cx_text {

        p {
            font-size: 20px;
            color: #292929;
            margin-bottom: 20px;

            @media (max-width: 767.98px) {
                font-size: 16px;
                margin-bottom: 16px;
            }
        }
    }
}

.cx_connection-texts-slider {
    text-align: center;

    .cx_title {
        font-size: 90px;
        line-height: 1;
        color: #292929;
        font-weight: 600;
        position: absolute;
        padding: 70px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        top: 160px;
        white-space: nowrap;
        text-align: center;
        will-change: transform;


        @media (max-width: 991.98px) {
            font-size: 68px;
            white-space: unset;
            padding: 45px 0;
        }

        @media (max-width: 767.98px) {
            font-size: 48px;
            padding: 36px 0;
            top: 200px;
        }
    }
}

.cx_brandtext-box {
    position: relative;
    z-index: 1;
    will-change: transform;
}

.cx_brand-box__inner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 640px;
    margin-left: auto;
    gap: 25px 10px;

    @media (max-width: 991.98px) {
        margin-top: 50px;
        margin-bottom: 0px;
        height: auto;
        margin-right: auto;
    }

    @media (max-width: 767.98px) {
        height: auto;
    }
}

.cx_brand-box__item {
    width: 100%;
    max-width: 32.24%;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform;

    img {
        max-width: 100%;
        height: 90px;
        object-fit: scale-down;
        opacity: 0.15;
    }

    @media (max-width: 767.98px) {
        max-width: calc(50% - 20px);

        img {
            height: 70px;
        }

        &:first-child {
            order: 0;
        }

        &:nth-child(2) {
            order: 1;
        }

        &:nth-child(3) {
            order: 3;
        }

        &:nth-child(4) {
            order: 2;
        }

        &:nth-child(5) {
            order: 4;
        }

        &:nth-child(6) {
            order: 5;
        }
    }
}

.cx_make-section {
    padding-top: 180px;
    position: relative;
    z-index: 2;

    .cx_section-title {
        font-size: 74px;
        line-height: 78px;
        letter-spacing: -0.023em;
        color: #292929;
        font-weight: 600;
        max-width: 560px;
        margin-bottom: 80px;
        will-change: transform;

        @media (max-width: 991.98px) {
            font-size: 72px;
            line-height: 72px;
            margin-bottom: 40px;
        }

        @media (max-width: 767.98px) {
            font-size: 48px;
            line-height: 54px;
            margin-bottom: 50px;
            max-width: 300px;
        }
    }

    @media (max-width: 991.98px) {
        padding-top: 100px;
    }

    @media (max-width: 767.98px) {
        padding-top: 70px;
    }
}

.cx_we-make-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;

    .cx_we-make-item {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 33.33%;
        width: 100%;

        @media (max-width: 767.98px) {
            max-width: 100%;
            margin-bottom: 26px;

        }
    }
}


.cx_wemake-card {
    position: relative;
    will-change: transform;

    &.is_visible {
        will-change: transform;

        .cx_wemake-card_body {
            &:after {
                transition: width 1.2s cubic-bezier(0.19, 1, 0.22, 1);
                width: 100%;
            }
        }
    }

    .cx_wemake-card_body {

        &:after {
            content: "";
            width: 0px;
            height: 2px;
            background-image: linear-gradient(90deg, rgb(233, 0, 205) 0%, rgb(255, 63, 11) 100%);
            background-color: rgb(233, 0, 205);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transition: width 1.2s cubic-bezier(0.19, 1, 0.22, 1);
        }


    }


    .cx_title {
        font-size: 34px;
        line-height: 42px;
        color: #292929;
        font-weight: 500;
        margin-bottom: 10px;

        @media (max-width: 991.98px) {
            font-size: 28px;
            line-height: 36px;
        }

        @media (max-width: 767.98px) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    .cx_subtitle {
        font-size: 20px;
        line-height: 22px;
        max-width: 240px;

        @media (max-width: 991.98px) {
            font-size: 16px;
            max-width: 100%;
            min-height: 44px;
        }

        @media (max-width: 767.98px) {
            min-height: auto;
        }
    }
}

.cx_wemake-card_header {
    position: relative;
}

.cx_wemake-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-appearance: button;
    transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    font-family: "Sora", sans-serif;
    color: #FF0089;
    margin-bottom: 13px;

    svg {
        transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
        width: 13px;
        height: 13px;
        margin-left: 10px;
    }

    &.active {
        svg {
            transform: rotate(90deg);
        }

        .line-horizontal {
            transform: scale(0);
        }
    }
}

.cx_wemake-card_body {
    transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    padding-top: 20px;

    @media (max-width: 991.98px) {
        position: relative;
    }

    @media (max-width: 767.98px) {
        padding-top: 16px;
    }

    .cx_wemake-card_body-inner {
        overflow: hidden;
        height: 0;
    }
}

.cx_wemake-list {
    list-style: none;
    padding-bottom: 20px;
    min-height: 125px;
    font-size: 16px;

    @media (max-width: 991.98px) {
        min-height: 230px;
    }

    @media (max-width: 767.98px) {
        min-height: auto;
    }

    .empty {
        min-height: 21px;
    }
}

.cx_what-newchapter-section {
    padding-bottom: 120px;

    .cx_logo {
        will-change: transform;
    }

    @media (max-width: 767.98px) {
        padding-top: 70px;
        padding-top: 70px;
    }
}

.cx_logo {
    text-align: center;
    margin-bottom: 100px;

    @media (max-width: 767.98px) {
        justify-content: flex-start;
    }

    svg {
        max-width: 1400px;
        margin: auto;

        @media (max-width: 991.98px) {
            height: auto;
        }
    }
}

.cx_logo_mobile {
    justify-content: flex-end;
    margin-bottom: 50px;
}

.cx_nextchapter-title {
    font-size: 95px;
    line-height: 95px;
    color: #292929;
    font-weight: 600;
    max-width: 700px;
    margin-bottom: 40px;
    background: -webkit-linear-gradient(0deg, #e900ce 0%, #ff7d00 90%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 991.98px) {
        font-size: 75px;
        line-height: 75px;
        max-width: 600px;
    }

    @media (max-width: 767.98px) {
        font-size: 54px;
        line-height: 58px;
        max-width: 290px;
    }
}

.cx_flashing-cursor {
    display: inline-block;
    width: 2px;
    height: 90px;
    margin-left: 0;
    border-radius: 0;
    background: #292929;
    -webkit-animation-name: blinker;
    animation-name: blinker;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
    -webkit-animation-duration: 1s;
    animation-duration: 1s;

    @media (max-width: 767.98px) {
        left: 50%;
        height: 60px;
        position: relative;
    }
}

@-webkit-keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.cx_home-insight-section {
    padding-top: 140px;
    padding-bottom: 100px;

    @media (max-width: 991.98px) {
        padding-top: 120px;
    }

    @media (max-width: 767.98px) {
        padding-top: 80px;
        padding-bottom: 0px;

        .cx_row {
            --bs-gutter-x: 0;
            margin-left: -30px;
            margin-right: -30px;
        }

        .cx_insight-card__content {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

}


.cx_parallax-particles {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &[data-speed="auto"] {
        transform: translateZ(0);
    }
}

.cx_left-parallax-one-group {
    width: 520px;
    height: 430px;
    position: absolute;
    left: -50px;
    top: 470px;

    @media (max-width: 1024.98px) {
        width: 290px;
        height: 240px;
        left: -85px;
        top: 400px;
    }

    @media (max-width: 512.98px) {
        left: -170px;
        top: 440px
    }

    svg {
        width: 100%;
    }


    .cx_parallax-particles {
        &:first-child {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='200.704' height='91.202' viewBox='0 0 200.704 91.202'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_87' data-name='Rectangle 87' width='26.51' height='203.14' transform='matrix(0.326, 0.946, -0.946, 0.326, 192.073, 0)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 200px;
            height: 90px;
            right: 120px;
            top: 30px;

            @media (max-width: 1024.98px) {
                width: 120px;
                height: 30px;
                right: 50px;
                top: 0;
            }
        }

        &:nth-child(2) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='244.563' height='188.008' viewBox='0 0 244.563 188.008'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_88' data-name='Rectangle 88' width='51.007' height='260.591' transform='matrix(-0.559, -0.829, 0.829, -0.559, 28.523, 188.008)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 250px;
            height: 190px;
            bottom: 125px;
            right: 135px;

            @media (max-width: 1024.98px) {
                width: 130px;
                height: 100px;
                bottom: 75px;
                right: 75px;
            }
        }

        &:nth-child(3) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='244.718' height='117.652' viewBox='0 0 244.718 117.652'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_89' data-name='Rectangle 89' width='11.873' height='262.591' transform='translate(244.718 106.806) rotate(114)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 245px;
            height: 120px;
            right: 270px;
            bottom: 150px;

            @media (max-width: 1024.98px) {
                width: 130px;
                height: 65px;
                right: 145px;
                bottom: 85px;
            }
        }

        &:nth-child(4) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='159.137' height='239.441' viewBox='0 0 159.137 239.441'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_85' data-name='Rectangle 85' width='27.873' height='262.591' transform='translate(159.137 225.085) rotate(149)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 160px;
            height: 240px;
            bottom: 0px;
            right: 0px;

            @media (max-width: 1024.98px) {
                width: 90px;
                height: 125px;
            }
        }
    }
}

.cx_right-parallax-one-group {
    position: absolute;
    right: -220px;
    top: 100px;
    width: 410px;
    height: 440px;

    @media (max-width: 1024.98px) {
        right: -50px;
        width: 190px;
        height: 300px;
        top: 150px;
    }

    @media (max-width: 512.98px) {
        right: -110px;
        top: 200px;
    }

    .cx_parallax-particles {
        &:first-child {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='137.059' height='251.894' viewBox='0 0 137.059 251.894'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_97' data-name='Rectangle 97' width='34.007' height='260.591' transform='translate(137.059 238.062) rotate(156)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 140px;
            height: 250px;
            left: 15px;
            top: 0;

            @media (max-width: 1024.98px) {
                width: 75px;
                height: 135px;
                left: 25px;
            }
        }

        &:nth-child(2) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='96.602' height='117.408' viewBox='0 0 96.602 117.408'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_105' data-name='Rectangle 105' width='15.873' height='136.591' transform='matrix(-0.788, -0.616, 0.616, -0.788, 12.508, 117.408)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 100px;
            height: 120px;
            right: 315px;
            top: 60px;

            @media (max-width: 1024.98px) {
                width: 56px;
                height: 62px;
                right: 115px;
                top: 20px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='53.795' height='62.387' viewBox='0 0 53.795 62.387'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_100' data-name='Rectangle 100' width='6.379' height='76.087' transform='matrix(-0.766, -0.643, 0.643, -0.766, 4.887, 62.387)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            }
        }

        &:nth-child(3) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='348.632' height='264.81' viewBox='0 0 348.632 264.81'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_95' data-name='Rectangle 95' width='65.628' height='376.259' transform='matrix(-0.559, -0.829, 0.829, -0.559, 36.699, 264.81)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 350px;
            height: 265px;
            bottom: 0;
            left: 60px;

            @media (max-width: 1024.98px) {
                width: 190px;
                height: 140px;
                left: 0;
            }
        }
    }
}

.cx_right-parallax-two-group {
    position: absolute;
    top: 250px;
    right: 0;
    width: 620px;
    height: 660px;

    @media (max-width: 1024.98px) {
        display: none;
    }

    .cx_parallax-particles {
        &:first-child {
            background-image: url("data:image/svg+xml,%3Csvg width='262' height='88' viewBox='0 0 262 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M254.894 87.4388L261.967 54.1799L7.10929 -0.0229976L0.0358288 33.2358L254.894 87.4388Z' fill='url(%23paint0_linear_120_28)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_120_28' x1='258.431' y1='70.8094' x2='3.57256' y2='16.6064' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF3E0A'/%3E%3Cstop offset='1' stop-color='%23FF00DB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            width: 265px;
            height: 90px;
            left: 0;
            top: 0;
        }

        &:nth-child(2) {
            background-image: url("data:image/svg+xml,%3Csvg width='86' height='133' viewBox='0 0 86 133' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.404329 120.438L22.3672 132.103L85.9594 12.3762L63.9965 0.710731L0.404329 120.438Z' fill='url(%23paint0_linear_120_27)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_120_27' x1='11.3858' y1='126.27' x2='74.9779' y2='6.54345' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF3E0A'/%3E%3Cstop offset='1' stop-color='%23FF00DB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            width: 85px;
            height: 135px;
            right: 175px;
            top: 130px;
        }

        &:nth-child(3) {
            background-image: url("data:image/svg+xml,%3Csvg width='109' height='52' viewBox='0 0 109 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.17625 40.3956L4.43115 51.48L108.61 11.4893L104.355 0.404941L0.17625 40.3956Z' fill='url(%23paint0_linear_120_29)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_120_29' x1='2.3037' y1='45.9378' x2='106.483' y2='5.94714' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF3E0A'/%3E%3Cstop offset='1' stop-color='%23FF00DB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            width: 110px;
            height: 55px;
            top: 195px;
            right: 275px;
        }

        &:nth-child(4) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='64.849' height='42.528' viewBox='0 0 64.849 42.528'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_113' data-name='Rectangle 113' width='6.504' height='71.747' transform='translate(64.849 36.953) rotate(121)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 65px;
            height: 45px;
            bottom: 0;
            right: 0;
        }
    }
}

.cx_center-parallax-one-group {
    position: absolute;
    left: -200px;
    width: 1820px;
    height: 980px;
    max-width: 100%;
    z-index: 0;
    top: 200px;

    @media (max-width: 1024.98px) {
        left: 0;
    }

    @media (max-width: 767.98px) {
        top: 300px;
    }

    .cx_parallax-particles {
        &:first-child {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='98.134' height='229.906' viewBox='0 0 98.134 229.906'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_112' data-name='Rectangle 112' width='32.119' height='230.591' transform='translate(98.134 220.516) rotate(163)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 100px;
            height: 230px;
            right: 230px;
            top: 0px;

            @media (max-width: 1024.98px) {
                width: 100px;
                height: 230px;
                right: 30px;
            }

            @media (max-width: 767.98px) {
                width: 60px;
                height: 140px;
                top: 0;
                right: 40px;
            }
        }

        &:nth-child(2) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='237.457' height='177.763' viewBox='0 0 237.457 177.763'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_108' data-name='Rectangle 108' width='48.573' height='251.591' transform='matrix(-0.545, -0.839, 0.839, -0.545, 26.454, 177.763)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 240px;
            height: 180px;
            right: 250px;
            top: 100px;

            @media (max-width: 1024.98px) {
                width: 240px;
                height: 180px;
                right: 50px;
            }

            @media (max-width: 767.98px) {
                width: 140px;
                height: 110px;
                top: 50px;
                right: 55px;
            }
        }

        &:nth-child(3) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='172.51' height='127.597' viewBox='0 0 172.51 127.597'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ea00ce'/%3E%3Cstop offset='1' stop-color='%23ff3600'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_45' data-name='Rectangle 45' width='48.493' height='171.201' transform='matrix(0.5, -0.866, 0.866, 0.5, 0, 41.996)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 170px;
            height: 130px;
            right: 505px;
            top: 140px;

            @media (max-width: 1024.98px) {
                width: 220px;
                height: 140px;
                right: 270px;
                top: 130px;
            }

            @media (max-width: 767.98px) {
                width: 110px;
                height: 75px;
                right: 180px;
                top: 85px;
            }
        }

        &:nth-child(4) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='165.08' height='230.621' viewBox='0 0 165.08 230.621'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_109' data-name='Rectangle 109' width='40.571' height='246.591' transform='matrix(-0.848, 0.53, -0.53, -0.848, 165.08, 209.121)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 165px;
            height: 230px;
            top: 160px;
            right: -8px;

            @media (max-width: 1024.98px) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='398.754' height='326.825' viewBox='0 0 398.754 326.825'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='-0.002' y1='0.494' x2='1.006' y2='0.496' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ea00ce'/%3E%3Cstop offset='1' stop-color='%23ff3600'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_105' data-name='Rectangle 105' width='466.861' height='50.064' transform='translate(0 287.368) rotate(-37.991)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
                width: 700px;
                height: 600px;
                top: 250px;
                right: -150px;
                z-index: 1;
                display: none;
            }

            @media (max-width: 767.98px) {
                width: 420px;
                height: 320px;
                top: 155px;
                right: -120px;
            }
        }

        &:nth-child(5) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='240.04' height='101.603' viewBox='0 0 240.04 101.603'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_111' data-name='Rectangle 111' width='17.463' height='249.089' transform='matrix(-0.342, -0.94, 0.94, -0.342, 5.973, 101.603)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 240px;
            height: 105px;
            top: 255px;
            right: 145px;

            @media(max-width: 1024.98px) {
                width: 240px;
                height: 110px;
                top: 275px;
                right: -70px;
            }

            @media(max-width: 767.98px) {
                width: 145px;
                height: 60px;
                top: 165px;
                right: -30px;
            }
        }

        &:nth-child(6) {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='163.812' height='278.458' viewBox='0 0 163.812 278.458'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23ff3e0a'/%3E%3Cstop offset='1' stop-color='%23ff00db'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle_110' data-name='Rectangle 110' width='24.873' height='302.148' transform='matrix(-0.883, -0.469, 0.469, -0.883, 21.962, 278.458)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E%0A");
            width: 160px;
            height: 280px;
            top: 310px;
            right: 460px;

            @media(max-width: 1024.98px) {
                top: 340px;
                right: 380px;
            }

            @media(max-width: 767.98px) {
                top: 200px;
                right: 165px;
                width: 100px;
                height: 165px;
            }
        }

        &:nth-child(7) {
            background-image: url("data:image/svg+xml,%3Csvg width='1618' height='591' viewBox='0 0 1618 591' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1617.35 421.961L45.2031 0.439941L0.000812099 169.031L1572.15 590.552L1617.35 421.961Z' fill='url(%23paint0_linear_120_33)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_120_33' x1='1595.27' y1='516.878' x2='1109.51' y2='-463.286' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF3E0A'/%3E%3Cstop offset='1' stop-color='%23FF00DB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            width: 1620px;
            height: 600px;
            bottom: 0;
            left: 0;

            @media (max-width: 1024.98px) {
                width: 890px;
                height: 330px;
                bottom: 200px;
                left: -300px;
            }

            @media (max-width: 767.98px) {
                width: 855px;
                height: 310px;
                bottom: 440px;
                left: -520px;
            }
        }
    }
}




/*Hero Section End CSS*/

/*Footer CSS Start*/
.cx_main-footer {
    background-color: #292929;
    padding-top: 80px;
    padding-bottom: 50px;
    position: relative;
    z-index: 6;
    clip-path: polygon(50% 0, 50% 5%, 100% 5%, 100% 100%, 0 100%, 0 0);

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        height: 20px;
        background-image: linear-gradient(90deg, #d500ac 0%, #fa4d00 90%);
    }
}

.cx_footer-left,
.cx_footer-right {
    max-width: 50%;

    @media (max-width: 767.98px) {
        max-width: 100%;
    }
}

.cx_footer-right {
    display: flex;
    flex-direction: column;

    @media (max-width: 767.98px) {
        position: absolute;
        left: 0;
        top: 110px;
        right: 0;
        padding-left: 20px;
    }
}

.cx_footer-title {
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 500;
    white-space: nowrap;

    @media (max-width: 767.98px) {
        white-space: unset;
        font-size: 20px;
    }
}

.cx_footer-email {
    color: #ff0089;
    font-size: 24px;
    line-height: 30px;
    text-decoration: none;

    @media (max-width: 767.98px) {
        font-size: 20px;
    }
}

.cx_footer-copyright {
    margin-top: 180px;
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;

    @media (max-width: 767.98px) {
        margin-top: 200px;
    }
}

.cx_footer-social {
    margin-top: 70px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 767.98px) {
        text-align: left;
        margin-top: 0px;
        justify-content: flex-start;
    }

    .cx_footer-social__item {
        display: inline-flex;
        align-items: center;
        margin-left: 12px;
        margin-right: 12px;

        a {
            display: flex;

            &:hover {
                opacity: 0.8;
            }
        }

        &:last-child {
            margin-right: 0px;
        }
    }
}

.cx_footer-menu {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    gap: 30px;

    @media(max-width: 767.98px) {
        justify-content: flex-start;
        margin-top: 50px;
        flex-wrap: wrap;
        padding-left: 10px;
        gap: 10px;
    }

    @media(max-width: 500.98px) {
        flex-direction: column;
    }
}

.cx_footer-menu__link {
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    display: block;
    font-weight: 400;

    &:hover {
        color: #ff0089;
    }
}

/*Footer CSS End*/
.hide {
    display: none;
}

.hide-desktop {
    display: none !important;
}

.show-desktop {
    display: flex !important;
}

@media (max-width: 512.98px) {
    .show-mobile {
        display: flex !important;
    }

    .hide-mobile {
        display: none !important;
    }
}

.cx_single-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
}

.cx_single-navigation__link {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 34px;
    text-decoration: none;
    color: #292929;
    width: 50%;
    max-width: 550px;

    svg {
        width: 60px;
    }

    &.cx_single-navigation__link--previous {
        svg {
            margin-right: 15px;
        }
    }

    &.cx_single-navigation__link--next {
        text-align: right;
        margin-left: auto;

        svg {
            margin-left: 15px;
        }
    }

    h3 {
        font-size: 28px;
        font-weight: 500;
        width: calc(100% - 60px);

        small {
            font-size: 20px;
            display: block;
            margin-bottom: 8px;
        }

    }


    &:hover {
        h3 {
            color: #ff0089;

            small {
                color: #292929;
            }
        }
    }

}

.cx_insight-related-section {
    padding-bottom: 40px;

    .cx_section-title {
        margin-bottom: 30px;
    }
}
.cx_holding-section {
    background-color: #292929;
    height: 100svh;
    overflow: hidden;
    position: relative;
}

.cx_holding-section-inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.cx_holding-lottie-first,  .cx_holding-lottie-two {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 30px);
    text-align: center;

    svg {
        max-width: 718px;
        margin-left: auto;
        margin-right: auto;
        
        @media (max-width: 767.98px) {
            max-width: 86%;
        }
    }

    @media (max-width: 767.98px) {
        height: calc(100% - 50px);
    }

    @media (max-height: 700px) {
        bottom: 50px;
        height: calc(100% - 70px);
    }

    @media (max-height: 300px) {
        bottom: 20px;
        height: calc(100% - 50px);
    }
}

.cx_holding-lottie-two {
    opacity: 0;
}

.cx_holding-email {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    color: #ff0089;
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
    font-weight: 500;

    @media (max-width: 767.98px) {
        font-size: 18px;
        bottom: 50px;
    }

    @media (max-height: 700px) {
        bottom: 50px;
    }

    @media (max-height: 300px) {
        bottom: 20px;
        font-size: 14px;
    }
}

.cx_holding-icon-desktop {
    width: 100%;
    height: 100%;
    @media (max-width: 767.98px) {
        display: none;
    }
}

.cx_holding-icon-mobile {
    display: block;
    width: 100%;
    height: 100%;
    @media (min-width: 768px) {
        display: none;
    }
}
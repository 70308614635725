.cx_ratio {
    position: relative;
    width: 100%;
    z-index: 0;

    &::before {
        display: block;
        padding-top: var(--bs-aspect-ratio);
        content: "";
    }

    &>* {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.cx_media-scale-down {
            object-fit: scale-down;
        }

        &.cx_media-cover {
            object-fit: cover;
        }
    }

    &.cx_bg-white {
        background-color: #fff;
    }
}

.cx_ratio-19x10 {
    --bs-aspect-ratio: 50%;

    // --bs-aspect-ratio: 56.25%;
    @media(max-width: 767.98px) {
        --bs-aspect-ratio: 64%;
    }
}

.cx_ratio-16x9 {
    --bs-aspect-ratio: 56.25%;
}

.cx_ratio-1x1 {
    --bs-aspect-ratio: 100%;
}

.cx_ratio-100vh {
    --bs-aspect-ratio: calc(100vh - 50px);

    @media(max-width: 767.98px) {
        --bs-aspect-ratio: 60vh;
    }
}


.cx_cs-single-section {
    padding: 0px;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;


    .cx_container {
        max-width: 84%;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);

        @media (max-width: 991.98px) {
            max-width: 100%;
        }
    }

    .cx_title {
        font-size: 94px;
        line-height: 94px;
        font-weight: 600;
        max-width: 780px;
        letter-spacing: -2px;

        @media (max-width: 998.98px) {
            font-size: 84px;
            line-height: 84px;
        }

        @media (max-width: 767.98px) {
            font-size: 48px;
            line-height: normal;
        }
    }

    @media (max-width: 1199.98px) {
        padding: 0 0px 0;
    }

    @media (max-width: 998.98px) {
        padding: 0 0px 0;
    }

    @media (max-width: 767.98px) {
        margin-bottom: 20px;
        min-height: unset;
    }
}

.cx_single-media-section {
    padding: 50px 50px;

    @media (max-width: 1199.98px) {
        padding: 40px 40px;
    }

    @media (max-width: 998.98px) {
        padding: 25px 25px;
    }

    @media (max-width: 767.98px) {
        padding: 14px 14px;
    }
}

.cx_single-content-section {
    padding: 50px 45px;

    .cx_text {
        margin-bottom: 30px;

        .cx_title {
            font-size: 74px;
            color: #292929;
            margin-bottom: 8px;
            line-height: 78px;
            font-weight: 600;
            letter-spacing: -0.023em;

            @media (max-width: 991.98px) {
                font-size: 48px;
                line-height: normal;
            }
        }

        .cx_subtitle {
            font-size: 20px;
            line-height: normal;

            @media (max-width: 991.98px) {
                font-size: 18px;
            }
        }

        @media (max-width: 1199.98px) {
            margin-bottom: 36px;
        }

        @media (max-width: 767.98px) {
            margin-bottom: 26px;
        }
    }

    .cx_description {
        font-size: 18px;
        color: #292929;
        max-width: 640px;

        p:not(:last-child) {
            margin-bottom: 16px;
        }

        @media (max-width: 991.98px) {
            font-size: 16px;

            p {
                margin-bottom: 16px;
            }
        }
    }

    .cx_row {
        justify-content: space-between;
    }

    .cx_col {
        &:last-child {
            .cx_description {
                margin-left: auto;

                @media (max-width: 991.98px) {
                    margin-left: 0;
                }
            }
        }
    }

    @media (max-width: 1199.98px) {
        padding: 30px 10px;
    }

    @media (max-width: 998.98px) {
        padding: 25px 0px;
    }

    @media (max-width: 767.98px) {
        padding: 12px 0px;
    }
}

.cx_single-testimonial-section {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;

    @media (max-width: 1199.98px) {
        padding: 30px 40px;
    }

    @media (max-width: 998.98px) {
        padding: 25px 0;
    }

    @media (max-width: 998.98px) {
        padding: 20px 0;
    }
    @media (max-width: 767.98px) {
        padding: 12px 0;
    }
}

.cx_testimonial-content {
    text-align: center;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;

    .cx_content {
        font-size: 48px;
        line-height: 54px;
        font-weight: 600;
        margin-bottom: 28px;

        @media (max-width: 1199.98px) {
            font-size: 40px;
            line-height: 46px;
            margin-bottom: 20px;
        }

        @media (max-width: 998.98px) {
            font-size: 34px;
            line-height: 40px;
        }

        @media (max-width: 768.98px) {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 10px;
        }
    }

    .cx_name {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 0px;

        @media (max-width: 1199.98px) {
            font-size: 18px;
        }

        @media (max-width: 767.98px) {
            font-size: 16px;
        }
    }
}

.cx_single-carousel-section {
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;

    .cx_container {
        max-width: 1028px;
    }

    .swiper {
        overflow: unset;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 50%;
        width: 54px;
        height: 50px;
        transform: translateY(-50%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        z-index: 5;

        @media (max-width: 991.98px) {
            width: 44px;
            height: 36px;
        }

        @media (max-width: 767.98px) {
            width: 28px;
            height: 24px;
        }
    }

    .swiper-button-prev {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54.768' height='51.743' viewBox='0 0 54.768 51.743'%3E%3Cg id='Group_116' data-name='Group 116' transform='translate(1715.316 7619.836) rotate(180)'%3E%3Cline id='Line_11' data-name='Line 11' x2='53.613' y2='0.465' transform='translate(1660.557 7593.5)' fill='none' stroke='%23ea00ce' stroke-width='2'/%3E%3Cpath id='Path_13' data-name='Path 13' d='M8213.093,6991.581l14.2,25.384-14.2,25.383' transform='translate(-6513.123 577)' fill='none' stroke='%23ea00ce' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
        left: 8%;

        @media (max-width: 767.98px) {
            left: 4%;
        }
    }

    .swiper-button-next {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54.768' height='51.743' viewBox='0 0 54.768 51.743'%3E%3Cg id='Group_115' data-name='Group 115' transform='translate(-1660.548 -7568.093)'%3E%3Cline id='Line_11' data-name='Line 11' x2='53.613' y2='0.465' transform='translate(1660.557 7593.5)' fill='none' stroke='%23ea00ce' stroke-width='2'/%3E%3Cpath id='Path_13' data-name='Path 13' d='M8213.093,6991.581l14.2,25.384-14.2,25.383' transform='translate(-6513.123 577)' fill='none' stroke='%23ea00ce' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
        right: 8%;

        @media (max-width: 767.98px) {
            right: 4%;
        }
    }

    @media (max-width: 1199.98px) {
        padding-bottom: 30px;
    }

    @media (max-width: 767.98px) {
        padding-bottom: 20px;
    }
}

.cx_carousel--images {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 0 14px;

    .cx_ratio-16x9 {
        --bs-aspect-ratio: 64%;
    }
}

.cx_single-gallery-section {
    padding: 50px 50px;
    position: relative;

    .cx_row {
        --bs-gutter-x: 40px;
        --bs-gutter-y: 40px;

        @media (max-width: 1199.98px) {
            --bs-gutter-x: 30px;
            --bs-gutter-y: 30px;
        }

        @media (max-width: 998.98px) {
            --bs-gutter-x: 20px;
            --bs-gutter-y: 20px;
        }

        @media (max-width: 767.98px) {
            --bs-gutter-x: 0px;
            --bs-gutter-y: 14px;
        }
    }

    .show-mobile {
        display: none !important;
    }

    @media (max-width: 1199.98px) {
        padding: 30px 30px;
    }

    @media (max-width: 998.98px) {
        padding: 25px 25px;
    }

    @media (max-width: 767.98px) {
        padding: 14px 14px;

        .hide-mobile {
            display: none !important;
        }

        .show-mobile {
            display: block !important;
        }

        .cx_ratio-1x1 {
            --bs-aspect-ratio: 64%;
        }
    }
}

.cx_thankyou-section {
    position: relative;
    padding: 50px 50px 150px;

    .cx_title {
        font-size: 16px;
        line-height: normal;
        font-weight: 700;
        margin-bottom: 0;
    }

    .cx_description {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 0px;

        @media (max-width: 767.98px) {
            margin-bottom: 26px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .cx_container {
        max-width: 1600px;
    }

    .cx_news_title {
        margin-top: 30px;
        font-weight: 700;
    }

    @media (max-width: 1199.98px) {
        padding: 60px 10px 100px;
    }

    @media (max-width: 998.98px) {
        padding: 50px 0px 80px;
    }

    @media (max-width: 767.98px) {
        padding: 50px 0px 60px;
    }
}
.cx_content-section {
    padding-top: 200px;
    padding-bottom: 100px;

    @media (max-width: 767.98px) {
        padding-top: 140px;
    }
}


.cx_content-section {
    table {
        border-collapse: collapse;
        text-align: left;
        border: solid 1px #cbcbcb;
        margin-bottom: 30px;

        th, td {
            padding: 10px;
            border-bottom: solid 1px #cbcbcb;
            border-right: solid 1px #cbcbcb;
            font-size: 16px;
            line-height: normal;
            vertical-align: top;
        }

        tr {
            &:nth-child(even) {
                background-color: #f7f7f7;
            }
        }

        thead {
            tr {
                background-color: #e1e1e1;
            }
        }
    }
    .cx_content__wrapper {
        font-size: 20px;
        line-height: 30px;

        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 24px;
        }

        p {
            margin-bottom: 24px;

            @media (max-width: 767.98px) {
                margin-bottom: 16px;
            }
        }

        h2 {
            font-size: 34px;
            line-height: 42px;
            margin-bottom: 24px;
            font-weight: 600;

            @media (max-width: 767.98px) {
                font-size: 24px;
                line-height: 34px;
                margin-bottom: 34px;
            }
        }

        ul, ol {
            padding-left: 36px;
            margin-bottom: 24px;
        }

        a {
            color: #ff0089;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
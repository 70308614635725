/*Insight Section Start*/
.cx_insight-section {
    padding-top: 200px;
    padding-bottom: 100px;

    @media (max-width: 767.98px) {
        padding-top: 140px;
    }
}

.cx_insight-card {
    margin-bottom: 70px;

    @media (max-width: 767.98px) {
        margin-bottom: 40px;
    }
}

.cx_insight-card__img {
    position: relative;
    &::before {
        display: block;
        padding-top: 56.25%;
        width: 100%;
        content: "";
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
    }

    .cx_insight-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        clip-path: polygon(0 0, 100% 0, 100% 94%, 50% 94%, 50% 100%, 0 100%);
    }

    .cx_insight-date {
        position: absolute;
        right: 0;
        font-size: 16px;
        letter-spacing: -1px;
        line-height: 22px;

        @media (max-width: 767.98px) {
            font-size: 14px;
        }
    }
}

.cx_insight-card__content {
    padding-top: 50px;
    color: #292929;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1px;

    @media (max-width: 991.98px) { 
        padding-top: 30px;
    }
    
    @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 26px;
        padding-top: 30px;
    }
}

.cx_insight-card__title {
    color: #292929;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1px;
    text-decoration: none;
    transition: color 0.5s cubic-bezier(0.16, 1, 0.3, 1);

    @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 26px;
        padding-top: 30px;
    }

    &:hover {
        color: #ff0089;
    }
}

.cx_insight-single-section {
    .cx_insight-card__content {
        font-size: 20px;
        line-height: 30px;

        @media (max-width: 767.98px) {
            font-size: 16px;
            line-height: 24px;
        }

        p {
            margin-bottom: 24px;

            @media (max-width: 767.98px) {
                margin-bottom: 16px;
            }
        }

        h2 {
            margin-bottom: 40px;
            font-size: 48px;
            line-height: 54px;

            @media (max-width: 767.98px) {
                margin-bottom: 30px;
                font-size: 34px;
                line-height: 48px;
            }

            .cx_title {
                font-size: 34px;
                line-height: 42px;
                margin-bottom: 60px;
                font-weight: 600;

                @media (max-width: 767.98px) {
                    font-size: 24px;
                    line-height: 34px;
                    margin-bottom: 34px;
                }
            }
        }

        ul {
            padding-left: 26px;
            margin-bottom: 24px;
        }

        a {
            color: #ff0089;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
/*Insight Section End*/
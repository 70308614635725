@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import './custom'; // Designer's custom.css
@import '../../components/casestudies/casestudies'; // Casestudies custom.css
@import '../../components/insights/insights'; // Insights custom.css
@import '../../components/pages/comingsoon'; // Comningsoon custom.css
@import '../../components/pages/commoncontent'; // CommonContent custom.css


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::after,
::before {
    box-sizing: border-box;
}

body {
    font-family: 'Sora', sans-serif;
    background: #ffffff;
    width: 100%;
}

a {
    color: #292929;
}